import { useNavigate } from "react-router-dom";
import { ToastContainer, } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Win from "../assets/winconfetti.png";
import lost from "../assets/lost-removebg-preview.png";
import "../Styles/PopUp.css";

const { REACT_APP_CONTACT_URL } = process.env;

interface ModalProps {
  prize: string;
  showToastMessage:()=>void;
}

export default function Modal({ prize,showToastMessage }: ModalProps) {
  const lose = prize === "No luck today";
  const navigate = useNavigate(); // Use useNavigate for navigation
  const contactBtn = () => {
    window.open(`${REACT_APP_CONTACT_URL}`, "_blank");
  };

  return (
    <div className="popup flex flex-col h-screen items-center justify-center z-30">
      <div className="modal z-4 flex flex-col items-center justify-center ">
        <div className="overlay"></div>
        <div className="modal-content  flex flex-col  items-center justify-evenly h-420 w-80 sm:w-96 mx-5 px-5 z-30 rounded-md border-4 border-amber-600">
          {lose ? (
            <img src={lost} className="h-36 sm:h-28" alt="lost" />
          ) : (
            <img src={Win} className="h-28" alt="won" />
          )}
          <div className="text-2xl md:text-3xl font-semibold">
            {lose ? <h2>Opps!</h2> : <h2>Congratulation!</h2>}
          </div>
          <div className=" text-xl md:text-2xl ">
            {lose ? (
              <p>Better luck next time</p>
            ) : (
              <p>
                You won <span className="font-extrabold"> {prize}</span> coupon
              </p>
            )}
          </div>
          {lose ? (
            <button className="btn" onClick={() => navigate("/")}>
              Ok
            </button>
          ) : (
            <button
              className="btn"
              onClick={() => {
                if (!lose) {
                  showToastMessage()
                  setTimeout(() => {
                    navigate("/"); // Redirect to the home page after a delay
                  }, 2000); // Adjust the delay time (in milliseconds) as needed
                }
              }}
            >
              Claim reward
            </button>
          )}
          <button className="btn" onClick={contactBtn}>
            Contact Us
          </button>
        </div>
      </div>
      <ToastContainer limit={1}></ToastContainer>
    </div>
  );
}
