
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Game from './Components/Game';
import Home from "./Components/Home"

function App() {
  return (
    <div className="App">
    <BrowserRouter><Routes>
        <Route path='/'  element={<Home/>}></Route>
        <Route path='game'  element={<Game/>}></Route>
        </Routes></BrowserRouter>
    </div>
  );
}

export default App;
