import React, { useRef, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment";
import SpinAndWin from "react-spin-game";
import "react-spin-game/dist/index.css";
import "../Styles/Game.css";
import PopUp from "../Components/PopUp";
import logo from "../assets/workplay-logo-with-shadow.png";
import pointer from "../assets/icons8-triangle-48.png";
import spinAudio from "../assets/spinning-reel-27903 (1)-[AudioTrimmer.com].mp3";

const { REACT_APP_API_URL, REACT_APP_LOGO_URL } = process.env;

const freeSpinGifts: Array<[string, string]> = [
  ["70% off", "#18453B"],
  ["No luck today", "#00A877"],
  ["30% off", "#367588"],
  ["50% off", "#008080"],
  ["80% off", "#18453B"],
  ["60% off", "#00A877"],
  ["40% off", "#367588"],
  ["90% off", "#008080"],
];

function getRandomGift(freeSpinGifts: Array<[string, string]>) {
  const randomIndex = Math.floor(Math.random() * freeSpinGifts.length);
  return freeSpinGifts[randomIndex];
}

const Game: React.FC = () => {
  const [randomGift, setRandomGift] = useState(getRandomGift(freeSpinGifts));
  const [showPopup, setShowPopup] = useState(false);
  const [disable, setDisable] = useState(false);
  const [timeoutId, setTimeoutId] = useState<null | NodeJS.Timeout>(null);
  const ref = useRef<any>(null);
  const toastId: any = React.useRef(null);

const showToastMessage = () => {
    toastId.current = toast("Please wait...", {
      autoClose: 3000,
      position: "top-center",
    });
  };

const sendMail = () => {
    const subject = `Great News! You won a coupon!`;
    const mail = localStorage.getItem("userEmail");
    const coupon = localStorage.getItem("Coupon");
    axios
      .post(`${REACT_APP_API_URL}/sendEmail`, {
        mail: mail,
        subject: subject,
        message: `Congratulations! You've won a ${coupon} discount coupon on the Slot Machine! Enjoy the rewards!`,
      })
      .then((response) => {
        console.log("Mail sent successfully:", response.data);
        toast.update(toastId.current, {
          render: "Your Coupon is on the Way",
          type: "success",
          autoClose: 3000,
          position: "top-center",
        });
      })
      .catch((error) => {
        console.error("Error sending mail:", error);
      });
    return;
  };

  const storeData = () => {
    const mail = localStorage.getItem("userEmail");
    const coupon = localStorage.getItem("Coupon");
    const timestamp = moment().format("YYYY-MM-DD HH:mm:ss");
    const gameResultData = {
      mail: mail,
      coupon: coupon,
      timestamp,
    };
    axios
      .post(`${REACT_APP_API_URL}/spin-result`, gameResultData)
      .then((response) => {
        console.log("Response:", response.data.message);
      })
      .catch((error: any) => {
        console.error("Error sending game result:", error);
      });
  };

  const handleSpinClick = () => {
    const newRandomGift = getRandomGift(freeSpinGifts);
    const audio = new Audio(spinAudio);
    audio.play();
    setRandomGift(newRandomGift);
    const User = newRandomGift[0];
    localStorage.setItem("Coupon", JSON.stringify(User));

    setDisable(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (User !== "No luck today") {
      localStorage.setItem("Coupon", JSON.stringify(User));
    }
    storeData();
    // Set a new timeout to show the pop-up after 8 seconds
    const newTimeoutId = setTimeout(() => {
      sendMail();
      setShowPopup(true);
    }, 8000);
    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    const element = document.querySelector("._1sp4O");
    if (element) {
      element.addEventListener("click", handleSpinClick);
    }
    if (disable) {
      element?.classList.add("disabled");
    }
    return () => {
      if (element) {
        element.removeEventListener("click", handleSpinClick);
      }
    };
  }, [randomGift]);

  return (
    <div className=" game-container relative mt-16 flex flex-col items-center justify-center ">
      <div className="z-20 absolute ">
        {showPopup && <PopUp showToastMessage={showToastMessage}  prize={randomGift[0]} />}
      </div>
      <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
        <img
          src={logo}
          className="h-10 mb-20 cursor-pointer"
          alt="Workplay-logo"
        />
      </a>
      <img
        className="absolute top-56 sm:top-72 h-4  w-6 sm:w-8 z-10 "
        src={pointer}
        alt="pointer"
      />
      <SpinAndWin
        ref={ref}
        data={freeSpinGifts}
        fontSize={22}
        result={randomGift[0]}
        removeButtonEffect={false}
        time={8}
      />
    </div>
  );
};

export default Game;
