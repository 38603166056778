import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Styles/Home.css";
import logo from "../assets/workplay-logo-with-shadow.png";
const { REACT_APP_LOGO_URL } = process.env;

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [showHome, setShowHome] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowHome(true);
    }, 100);
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleStartClick = () => {
    // Validate the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);

    if (isValid) {
      // Store the email in localStorage
      localStorage.setItem("userEmail", email);
      navigate("/game");
    } else {
      alert("Please enter valid email address.");
    }
  };

  return (
    <div className={`home-container ${showHome ? "visible" : ""}`}>
      <div className="flex flex-col h-screen items-center py-16 px-10 gap-y-8 sm:gap-y-10">
        <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
          <img src={logo} className="h-10 cursor-pointer" alt="workplay-logo" />
        </a>
        <h1 className="text-2xl sm:text-3xl font-bold text-white ">
          Welcome to the Spin the Wheel Game!
        </h1>
        <p className="text-lg w-72 sm:text-xl text-white md:w-96">
          Enter your email address and start the game to win exciting rewards
        </p>
        <input
          type="email"
          placeholder="e.g., abc@example.com"
          className="w-64 px-4 h-14 my-4 bg-green-200 text-black text-center rounded-md outline-none border-2 border-orange-500"
          value={email}
          onChange={handleEmailChange}
          required
          pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" // Email validation pattern
        />
        <button className="btn uppercase" onClick={handleStartClick}>
          start
        </button>
      </div>
    </div>
  );
};

export default Home;
